<template>
  <div class="popup flex-center" @click="closePop">
    <div class="popupBox" @click.stop v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <img src="@/assets/popclose.png" class="popclose" @click="closePop">
        <div class="popcard">
            <div class="size-30 text-b">{{title}}</div>
            <slot></slot>
        </div>
        <div class="popupBtn" @click="submit">{{$t('确定')}}</div>
    </div>
  </div>
</template>

<script>
export default {
    name:"PopupBox",
    props:{
        title:{
            type:String,
            default:''
        },
        loading:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        closePop(){
            if(this.loading)return
            this.$emit('close')
        },
        submit(){
            if(this.loading)return
            this.$emit("submit")
        }
    }
}
</script>

<style lang="scss">
.popup{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    .popupBox{
        width: 6.15rem;
        height: 4.34rem;
        padding: .7rem .4rem 0 .4rem;
        transform: scale(0);
        animation: show 0.3s linear forwards;
        background-image: url("@/assets/pop.png");
        background-size: 100% 100%;
        position: relative;
        .popcard{
            width: 100%;
            height: 2.96rem;
            background-color: #021C1C;
            padding: .3rem .3rem 0 .3rem;
        }
        .popupBtn{
            width: 3.24rem;
            height: .78rem;
            line-height: .78rem;
            background-image: url("@/assets/popbtn.png");
            background-size: 100% 100%;
            text-align: center;
            position: absolute;
            left: 1.51rem;
            bottom: .26rem;
            z-index: 1;
            color: #021C1C;
            font-weight: bold;
            font-size: .28rem;
        }
        .popclose{
            width: .6rem;
            height: .6rem;
            position: absolute;
            top: .1rem;
            right: .45rem;
            z-index: 1;
        }
    }
}
@keyframes show {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}
</style>