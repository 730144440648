<template>
  <div class="list mt-30" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">

    <div class="tab flex ae size-26 text-b">
      <div class="flex-center mr-20" :class="current == index ? 'tabAct' : 'tabDef'" v-for="(item, index) in tabs" :key="index"
        @click="tabsClick(index)">{{ item.name }}</div>
    </div>
    <div class="tableTh size-24 flex ac">
      <div class="td flex-s">{{ $t('序号') }}</div>
      <div class="flex-2 text-c">{{ $t('日期') }}</div>
      <div class="flex-1 text-c">{{ $t('本金') }}</div>
      <div class="flex-1 text-c">{{ $t('盈利') }}</div>
      <div class="td1 text-r flex-s">{{ $t('赎回进展') }}</div>
    </div>
    <div class="content size-20">
      <div v-for="(item, index) in list" :key="index">
        <div class="item flex" v-if="(current == 0 && item.status == 1) || (current == 1 && item.status == 0)">
          <div class="td flex-s">{{ index + 1 }}</div>
          <div class="flex-2 text-c">
            <div>{{ item.created_at }}</div>
            <div class="mainColor mt-10" v-if="item.status == 1">{{ item.countdownTxt || '--' }}</div>
          </div>
          <div class="flex-1 text-c">{{ item.amount }}</div>
          <div class="flex-1 text-c">{{ item.reward }}</div>
          <div class="td1 flex je flex-s text-b">
            <div class="tablebtn disbtn size-20 flex-center" v-if="item.status == 0">{{ $t('已结束') }}</div>
            <div class="tablebtn disbtn size-20 flex-center" v-if="item.status == 1 && !item.is_unstaking">{{ $t('等待赎回') }}
            </div>
            <div class="tablebtn size-20 flex-center" v-if="item.status == 1 && item.is_unstaking"
              @click="cancel(item.index)">{{ $t('赎回') }}</div>
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeList",
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      current: 0,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "staking"
    ]),
    tabs() {
      return [
        { name: this.$t('投资列表'), value: "0", left: "1.4rem" },
        { name: this.$t('赎回列表'), value: "1", left: "4.9rem" },
      ]
    }
  },
  methods: {
    tabsClick(index) {
      if (this.current == index) return;
      this.current = index;
    },
    async cancel(index) {
      this.loading = true
      this.staking.unstake(index).then(async result => {
        await result.wait()
        this.showToast(this.$t('操作成功'), 'success')
        this.loading = false
        this.$emit('refresh')
      }).catch(() => {
        this.showToast(this.$t('操作失败'), 'error')
        this.loading = false
      })
    }
  },
};
</script>

<style lang="scss" scoped>
$main-color: #0CEEDB;

.homeList {
  border-radius: 0.4rem;
  background: rgba(19, 98, 253, 0.1);

  .tabs {
    height: 1rem;
    position: relative;

    .tabLine {
      width: 0.52rem;
      height: 0.06rem;
      border-radius: 0.03rem;
      background-color: #1362fd;
      position: absolute;
      bottom: 0;
      z-index: 1;
      transition: all 0.3s;
    }
  }

  .listTh {
    height: 0.72rem;
    background: rgba(191, 213, 255, 0.1);
    color: #165498;
  }

  .listTr {
    border-bottom: 0.01rem solid #12213F;
    padding: .3rem 0;
    color: #ACACAC;

    .listBtn {
      border-radius: .12rem;
      padding: .1rem;
      background-color: #1362FD;
    }

    .disableBtn {
      background-color: #111e38 !important;
      color: #455370 !important;
    }
  }
}


.list {
  width: 100%;
  height: 10rem;
  background-image: url("@/assets/5.png");
  background-size: 100% 100%;

  .tab {
    height: 1rem;
    width: 100%;
    padding-left: .3rem;

    .tabAct {
      min-width: 1.68rem;
      padding: 0 .4rem 0 .1rem;
      height: .58rem;
      background-size: 100% 100%;
      background-image: url("@/assets/6.png");
      color: $main-color;
    }

    .tabDef {
      min-width: 1.68rem;
      padding: 0 .4rem 0 .1rem;
      height: .58rem;
      background-size: 100% 100%;
      background-image: url("@/assets/7.png");
      color: #599D98;
    }
  }

  .tableTh {
    width: 100%;
    height: .7rem;
    background-color: #205E59;
    padding: 0 .3rem;
  }

  .td {
    width: .8rem;
  }

  .td1 {
    width: 1.5rem;

    .tablebtn {
      min-width: 1rem;
      height: .44rem;
      padding: 0 .1rem;
      border-radius: .12rem;
      background-color: $main-color;
      color: #021C1C;
    }

    .disbtn {
      background-color: #205E59 !important;
      color: #599D98 !important;
    }
  }

  .content {
    width: 100%;
    height: 8.3rem;
    overflow-y: scroll;
    padding: 0 .3rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      border-bottom: 0.01rem solid #216371;
      padding: .3rem 0 .2rem 0;
    }
  }
}
</style>