import { getSign, getWalletAddress } from '@/dapp/web3-init'
import config from '@/config';
const { setAddress, usdt_address, getAddress } = config
import dapp from "@/dapp";

const state = {
	address: null,
	erc20: null,
	staking: null,
	referral: null,
	uniswapV2Router: null,
	isLoginIn: null
}
const mutations = {
	SET_ADDRESS(state, payload) {
		state.address = payload
	},
	SET_ERC20(state, payload) {
		state.erc20 = payload
	},
	SET_STAKING(state, payload) {
		state.staking = payload
	},
	SET_REFERRAL(state, payload) {
		state.referral = payload
	},
	SET_UNISWAPV2ROUTER(state, payload) {
		state.uniswapV2Router = payload
	},
	SET_LOGIN(state, payload){
		state.isLoginIn = payload
	}
}
const actions = {
	async init({ commit, dispatch }) {
		// 获取地址
		try {
			const address = await getWalletAddress()
			if(getAddress() != address) {
				commit('SET_LOGIN', 0)
				await getSign('Login-')
			}
			// 初始化ERC20
			const erc20 = await dapp.erc20.init(usdt_address)
			commit('SET_ERC20', erc20)
			// 初始化staking
			const staking = await dapp.staking.init()
			commit('SET_STAKING', staking)
			// 初始化referral
			const referral = await dapp.referral.init()
			commit('SET_REFERRAL', referral)
			// 初始化uniswapV2Router
			const uniswapV2Router = await dapp.uniswapV2Router.init()
			commit('SET_UNISWAPV2ROUTER', uniswapV2Router)
			// 保存地址
			setAddress(address)
			commit('SET_ADDRESS', address)
			commit('SET_LOGIN', 1)
		} catch (error) {
			commit('SET_LOGIN', 2)
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
