<template>
  <div id="app" @click="hidePicker">
    <div v-if="isLoginIn==1">
      <Navbar ref="navbar"></Navbar>
      <div class="gap100"></div>
      <HomePage></HomePage>
    </div>

    <div class="loading" v-if="isLoginIn==0 || isLoginIn==2"></div>
    
  </div>
</template>

<script>
import Navbar from "./components/NavBar.vue"
import HomePage from "./components/HomePage.vue";
import { getUrlParam } from '@/utils'
export default {
  name: 'App',
  components: {
    Navbar,
    HomePage
  },
  computed:{
    isLoginIn(){
      return this.$store.state.dapp.isLoginIn
    }
  },
  async mounted(){
    // 记录邀请码
    const ref = getUrlParam(this.$config.inviteKey)
    
    this.$store.commit('user/SET_REF', ref)
    // 初始化Dapp相关
    this.$store.dispatch('dapp/init')
  },
  methods:{
    hidePicker(){
      this.$refs.navbar.hideLanuage()
    }
  }
}
</script>

<style lang="scss">
@import "@/style/index.scss";
.loading{
  width: 100vw;
  height: 100vh;
  background-image: url("@/assets/loadBg.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loadImg{
    width: 2.9rem;
    height: 2.9rem;
  }

  .loadTxt{
    color: #0B93FB;
  }
}
.gap100{
  width: 100vw;
  height: 1rem;
}
</style>
