export default {
    // 授权额度
    approveAmount: '115792089237316195423570985008687907853269984665640564039457584007913129639935',

	rpc: 'https://bscrpc.com',

	// chainId:56,

	// usdt_address:'0x55d398326f99059fF775485246999027B3197955',

	// laf_address:'0x3BEC20CA77e100C50ef0d0066f4c2B348e615F48',

	// uniswapV2Router_address:'0x10ED43C718714eb63d5aA57B78B54704E256024E',

	// staking_biz_address:'0x3273A291FF46AB09804eA7959c85b75F431E0aAE',

	// referral_biz_address:'0xfA4289B3E5dBab53D7f0566361678B600BE34DaB'

	chainId:56,

	usdt_address:'0x55d398326f99059fF775485246999027B3197955',

	laf_address:'0xC096cd94EFbA332880e96AFa6b95258B86724589',

	uniswapV2Router_address:'0x10ED43C718714eb63d5aA57B78B54704E256024E',

	// staking_biz_address:'0x573a4793C01Ed78622DC87Cb8e421aC509948153',
	staking_biz_address:'0xa20573C123D4f9Cdc5a4a0c24Bf3922A50250D8f',

	referral_biz_address:'0x6D4a03DBdc7BB09cF565D3258EAb9Cc5024C912E'
}